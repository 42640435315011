<template>
  <v-container>
    <v-row>
      <v-col style="display: flex">
        <v-btn large icon :to="{ name: 'Contrataciones' }"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span class="text-h4">Detalle de Empleado</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />
    <div v-if="empleado">
      <EmpleadoCard :empleado="empleado"> </EmpleadoCard>
    </div>
    <br />
    <v-row>
      <v-col md="6">
        <div v-if="contratacion">
          <ContratacionCard :contratacion="contratacion"> </ContratacionCard>
        </div>
      </v-col>
      <v-col md="6">
        <div>
          <TurnoEmpleadoVigenteCard
            v-if="empleado"
            :turnoEmpleado="turnoEmpleado"
            :empleadoId="this.empleado.id"
          >
          </TurnoEmpleadoVigenteCard>
        </div>
      </v-col>
      <!-- <v-col md="4">
        <div v-if="contratacion">
          <ContratacionCard :contratacion="contratacion"> </ContratacionCard>
        </div> 
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import EmpleadoCard from "../components/EmpleadoCard.vue";
import ContratacionCard from "../components/ContratacionCard.vue";
import TurnoEmpleadoVigenteCard from "../components/TurnoEmpleadoVigenteCard.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "EmpleadoDetalle",
  components: {
    EmpleadoCard,
    ContratacionCard,
    TurnoEmpleadoVigenteCard,
  },
  data() {
    return {
      empleado: null,
      contratacion: null,
      foto: null,
      turnoEmpleado: null,
    };
  },
  props: ["id"],
  created() {
    PresentismoService.getContratacion(this.id)
      .then((response) => {
        this.empleado = response.data.empleado;
        this.contratacion = response.data;
      })
      .then((response) => {
        PresentismoService.getTurnoEmpleadoVigente(this.empleado.id).then(
          (response) => {
            this.turnoEmpleado = response.data;
          }
        );
      })
      .catch((error) => console.log(error));
  },
};
</script>
