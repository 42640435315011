<template>
  <div v-if="this.contratacion.id">
    <ConfirmDialog
      ref="confirmDialog"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :dialogYes="dialogYes"
      :dialogNo="dialogNo"
      :id="this.contratacion.id"
      @dialog-yes="handleDialogYes"
    ></ConfirmDialog>
    <v-card v-if="this.contratacion" height="auto">
      <v-container>
        <v-alert dense v-if="error" type="error">{{ this.error }}</v-alert>

        <v-row>
          <v-col cols="10">
            <v-card-title class="person-name"> Contratación </v-card-title>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="10">
            <v-card-text>
              <p>
                <strong>Tipo de Contratación: </strong
                >{{ this.contratacion.tipo.nombre }}
              </p>
              <p><strong>Desde: </strong>{{ this.contratacion.desde }}</p>
              <p v-if="this.contratacion.hasta">
                <strong>Hasta: </strong>{{ this.contratacion.hasta }}
              </p>
              <v-spacer></v-spacer>
            </v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :to="'/contratacion/' + this.contratacion.id + '/editar'"
            ><v-icon left darf>mdi-pencil</v-icon>Editar</v-btn
          >
          <v-btn color="error" @click="handleDialog"
            ><v-icon left darf>mdi-delete</v-icon>Eliminar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "ContratacionCard",
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      dialogTitle: "Confirmar Eliminar",
      dialogText: "¿Desea eliminar la contratación?",
      dialogYes: "Eliminar",
      dialogNo: "Cancelar",
      dialogId: null,
      error: "",
    };
  },
  created() {},
  props: {
    contratacion: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleDialog() {
      this.$refs.confirmDialog.dialog = true;
      this.dialogId = this.contratacion.id;
    },
    handleDialogYes(id) {
      //console.log(id);
      PresentismoService.deleteContratacion(id)
        .then((response) => {
          this.$router.push({
            name: "Contrataciones",
          });
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.error = "Error: Registro no encontrado.";
          else if (error.response.status === 405)
            this.error =
              "Error: El registro está protegido. Compruebe que no esté asignado a un empleado.";
        });
    },
  },
  computed: {
    nombreCompleto: function () {
      return (
        this.contratacion.empleado.nombres +
        " " +
        this.contratacion.empleado.apellidos
      );
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
