<template>
  <div>
    <ConfirmDialog
      v-if="this.turnoEmpleado"
      ref="confirmDialog"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :dialogYes="dialogYes"
      :dialogNo="dialogNo"
      :id="this.turnoEmpleado.id"
      @dialog-yes="handleDialogYes"
    ></ConfirmDialog>
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="10">
            <v-card-title class="person-name"> Turno Vigente </v-card-title>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="10">
            <v-card-text>
              <div v-if="this.turnoEmpleado">
                <p>
                  <strong>Nombre: </strong>{{ this.turnoEmpleado.turno.nombre }}
                </p>
                <p v-if="this.turnoEmpleado.turno.descripcion">
                  <strong>Descricpion: </strong
                  >{{ this.turnoEmpleado.turno.descripcion }}
                </p>
                <p><strong>Desde: </strong>{{ this.turnoEmpleado.desde }}</p>
                <p v-if="this.turnoEmpleado.hasta">
                  <strong>Hasta: </strong>{{ this.turnoEmpleado.hasta }}
                </p>
              </div>
              <div v-else>
                <p>No posee un turno vigente</p>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :to="'/turnos/asignados/' + this.empleadoId"
            ><v-icon left darf>mdi-view-list</v-icon>Ver Todos</v-btn
          >

          <v-btn
            v-if="this.turnoEmpleado"
            color="primary"
            :to="'/turnos/asignar/' + this.turnoEmpleado.id + '/editar'"
            ><v-icon left darf>mdi-pencil</v-icon>Editar</v-btn
          >
          <v-btn v-if="this.turnoEmpleado" color="error" @click="handleDialog"
            ><v-icon left darf>mdi-delete</v-icon>Eliminar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog.vue";
import PresentismoService from "@/services/PresentismoService.js";
import Helpers from "../../../ControlTss-Reloj-Web/src/helpers/helpers.js";
import Integer from "@zxing/library/esm/core/util/Integer.js";

export default {
  name: "TurnoEmpleadoVigenteCard",
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      dialogTitle: "Confirmar Eliminar",
      dialogText: "¿Desea eliminar el turno asignado?",
      dialogYes: "Eliminar",
      dialogNo: "Cancelar",
      dialogId: null,
      error: "",
      loadingSave: false,
    };
  },
  created() {},
  props: {
    turnoEmpleado: {
      type: Object,
      required: false,
    },
    empleadoId: {
      type: Number,
      required: false,
    },
  },
  methods: {
    handleDialog() {
      this.$refs.confirmDialog.dialog = true;
      this.dialogId = this.turnoEmpleado.id;
    },
    handleDialogYes(id) {
      this.loadingSave = true;
      PresentismoService.deleteTurnoEmpleado(id)
        .then((response) => {
          this.$router.push({
            name: "EmpleadoDetalle",
            params: { id: this.turnoEmpleado.empleado },
          });
        })
        .catch((error) => {
          this.error = Helpers.handleError(error);
          this.loadingSave = false;
        });
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
